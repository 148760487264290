@import "../../styles/colors";

.content {
  margin-top: 80px;
  max-width: 745px;
  z-index: 3;
  position: relative;

  @media (max-width: 768px) {
    margin-top: 50px;
  }

  p {
    font-weight: 600;
    font-size: 48px;
    line-height: 58px;
    margin: 0 0 40px;

    @media (max-width: 768px) {
      font-size: 28px;
      line-height: 130%;
    }
  }

  a {
    text-decoration: none;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
    color: $white;
    font-size: 34px;
    padding: 30px 32px;
    background: $black;
    border-radius: 48px;
    font-weight: 700;

    @media (max-width: 768px) {
      border-radius: 28px;
      padding: 10px 15px;
      font-size: 28px;
    }
  }
}