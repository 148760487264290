@import "../../styles/colors";

.opt-out {
  margin-bottom: 50px;
}

.opt-out-mid-area {
  margin-top: 80px;
  max-width: 800px;
  position: relative;
  z-index: 3;

  @media (max-width: 768px) {
    margin-top: 50px;
  }

  h1 {
    font-size: 40px;
    line-height: 120%;
    font-weight: 700;
    margin: 0 0 5px;
  }

  p, a, td {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: $black;
    word-break: break-word;
  }

  ul, li {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
  }

  .opt-out-list-head-right, .opt-out-content__point strong {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
    margin: 0 0 5px;

    p {
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
    }
  }

  table {
    margin-bottom: 20px;
    min-width: 700px;
  }

  .opt-out-content__table_collect {
    overflow-x: auto;
  }

  table tr td {
    border: 1px solid #4a4a4a;

    p {
      margin-bottom: 0;
    }
  }

  td {
    padding: 5px;
  }

  .opt-out-content__table_business-purpose {
    @media (max-width: 768px) {
      overflow-x: auto;
    }

    table {
      min-width: 550px;
    }

    table tr td {
      border: 0;

      @media (max-width: 768px) {
        border: 1px solid #4a4a4a;
      }
    }
  }
}