.header {
  padding-top: 84px;

  @media (max-width: 768px) {
    padding-top: 30px;
  }

  a {
    display: block;
    width: fit-content;
    z-index: 3;
    position: relative;

    img {
      width: auto;
      display: block;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }
}