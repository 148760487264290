@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), local('Montserrat-Regular'), url('fonts/Montserrat/montserrat.woff2') format('woff2'), url('fonts/Montserrat/montserrat.woff') format('woff'), url('fonts/Montserrat/montserrat.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat Bold'), local('Montserrat-Bold'), url('fonts/Montserrat/montserratbold.woff2') format('woff2'), url('fonts/Montserrat/montserratbold.woff') format('woff'), url('fonts/Montserrat/montserratbold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src: local('Montserrat SemiBold'), local('Montserrat-SemiBold'), url('fonts/Montserrat/montserrat-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Roboto';
    src: local('Roboto'), local('Roboto'), url('fonts/Roboto/Roboto-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}
