@import "../../styles/colors";
@import "../../styles/variables";

.footer {
  height: 100px;
  border-top: 1px solid $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
  position: relative;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    height: initial;
    padding: 15px 0;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 25px;

    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      display: inline;
    }
  }

  p, a {
    font-family: $font-roboto-black;
    margin: 0;
    color: $black;
    font-size: 16px;
    font-weight: 900;
    line-height: 24px;
    text-decoration: none;
  }

  a {
    position: relative;

    @media screen and (max-width: 768px) {
      padding-right: 40px;

      &:last-of-type {
        &:after {
          display: none;
        }
      }

      &:after {
        content: '';
        bottom: 5px;
        width: 1px;
        background: #000;
        height: 10px;
        position: absolute;
        right: 21px;
      }
    }
  }
}