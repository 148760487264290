@import "colors";
@import "variables";

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

blockquote, q {
  quotes: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html, body {
  height: 100%;
}

main {
  flex-grow: 1;
}

#root {
  height: 100%;
}

body {
  font-size: 10px;
  background: $dandelion;
  font-family: $font-regular;
  margin: 0;
  padding: 0;
  position: relative;
}

.container {
  max-width: 1280px;
  padding: 0 30px;
  margin: 0 auto;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100dvh;
  overflow: hidden;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0 0 20px;
}

button {
  background: transparent;
  border: 0;
  outline: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.main-bg {
  position: absolute;
  right: 15px;
  top: -20px;
  pointer-events: none;
  -webkit-mask-image: -webkit-gradient(linear, left top, left bottom,
          from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));

  &.no-opacity {
    -webkit-mask-image: none;
  }
  
  @media (max-width: 768px) {
    display: none;
  }
}